.form {
  margin: 0 20px;
}

.label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.checkboxGroup {
  height: 36vh;
  overflow-y: auto;
  margin-right: -20px;
  margin-top: 10px;
}

.checkboxGroup::-webkit-scrollbar {
  width: 8px;
}

.checkboxGroup::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
}
