.form {
  padding: 0 20px;
  position: relative;
}

.addDocumentBtn {
  border: none;
  background-color: #ffffff;
  color: var(--bgMainColor);
  cursor: pointer;
}

.documentPreview {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.documentItem {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: rgba(1, 1, 1, 0.05);
  border-radius: 10px;
  width: fit-content;
  padding: 5px 10px;
}

.documentName {
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconItem {
  cursor: pointer;
}

.label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content {
  font-weight: 600;
}

.taskName {
  font-weight: 600;
  color: var(--bgMainColor);
}

.buttonGroup {
  display: flex;
  justify-content: center;
}

.required {
  color: #fd0b0b;
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  .buttonGroup {
    padding-left: 30px;
  }
  .addDocumentBtn {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 574px) {
  .buttonGroup {
    padding-left: 0;
  }
  .addDocumentBtn {
    font-size: 3.8vw;
  }
}
