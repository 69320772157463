.form {
  padding: 20px 20px 0 20px;
  overflow-y: auto;
}

.editAvatarWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.wrapper {
  position: relative;
}

.editAvatarIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  right: 6%;
  bottom: 6%;
  cursor: pointer;
}

.avatarEdit {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.buttonGroup {
  display: flex;
  justify-content: center;
}
