.profileScreen {
  display: flex;
  overflow-y: auto;
}

.screenRight {
  background-color: white;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  box-sizing: border-box;
  border-radius: 10px;
  width: calc(100% - 310px);
  height: calc(100vh - 100px);
  margin: 20px;
  margin-left: 0;
  /* overflow-y: auto; */
}

.profileScreen::-webkit-scrollbar {
  width: 8px;
}

.profileScreen::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.information {
  box-sizing: border-box;
  max-width: 60%;
  margin: 0 auto;
  padding: 30px 0;
}

@media only screen and (max-width: 991px) {
  .information {
    max-width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .profileScreen {
    flex-direction: column;
  }
  .screenRight {
    margin: 20px;
    width: calc(100% - 40px);
    height: calc(100vh - 200px);
  }
}

@media only screen and (max-width: 574px) {
  .screenRight {
    /* height: calc(100vh - 160px); */
    height: fit-content;
  }
}
