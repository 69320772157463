.breadcrumbs {
  margin-bottom: 20px;
}

.separate {
  font-size: 12px;
  color: var(--textMainColor);
  padding-right: 10px;
  padding-left: 10px;
}

.link {
  color: var(--textMainColor);
  font-size: 15px;
}
