.loginScreen {
  width: 500px;
  margin: auto;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
}

.login {
  margin-top: 15vh;
}

.register {
  margin-top: 5vh;
}

.logo {
  width: 120px;
  height: auto;
}

.title {
  color: var(--bgMainColor);
  font-weight: 700;
  font-size: 36px;
  margin: 20px 0;
}

.loginText {
  margin: 20px 0;
  font-size: 22px;
}

.introText {
  margin-top: 30px;
  font-size: 14px;
}

.registerLink,
.registerLink:hover,
.registerLink:visited {
  color: var(--bgMainColor);
  text-decoration: none;
}

.authError {
  margin-top: 10px;
  color: #fd0b0b;
}

.forgetPassword {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  cursor: pointer;
  color: var(--bgMainColor);
}

@media only screen and (max-width: 767px) {
  .loginScreen {
    width: 60vw;
    padding: 6vw;
  }
  .login {
    margin-top: 16vh;
  }
  .register {
    margin-top: 8vh;
  }
  .logo {
    width: 16vw;
  }
  .title {
    font-size: 4.5vw;
    margin: 2.8vw 0;
  }
  .loginText {
    font-size: 3vw;
    margin: 2.8vw 0;
  }
  .introText {
    font-size: 2vw;
    margin-top: 3.5vw;
  }
  .forgetPassword {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 574px) {
  .loginScreen {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    /* height: fit-content; */
    background-color: #ffffff;
    border-radius: 0;
    overflow-y: auto;
  }
  .login {
    margin-top: 0;
    padding: 12vh 6vw 6vh 6vw;
  }
  .register {
    margin-top: 0;
    padding-top: 5vh;
  }
  .logo {
    width: 20vw;
  }
  .title {
    font-size: 8vw;
  }
  .loginText {
    font-size: 4vw;
  }
  .introText {
    font-size: 3.5vw;
  }
  .forgetPassword {
    font-size: 3.5vw;
  }
}

@media only screen and (max-width: 420px) {
  .logo {
    width: 24vw;
  }
  .title {
    font-size: 9vw;
  }
  .loginText {
    font-size: 6vw;
  }
  .introText {
    font-size: 4.8vw;
  }
  .forgetPassword {
    font-size: 4.8vw;
  }
}
