.projectListScreen {
  max-width: 1520px;
  margin: 0 auto;
  padding: 30px 20px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  scrollbar-width: 8px;
}

.projectListScreen::-webkit-scrollbar {
  width: 8px;
}

.projectListScreen::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.projectListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}
.createBtn {
  width: 80px;
  height: 40px;
  background-color: var(--bgMainColor);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.projectListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  cursor: pointer;
}

.projectItem {
  box-sizing: border-box;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  padding: 20px;
  height: 310px;
}

/* .projectItem:hover {
  transform: scale(1.05, 1.05);
  transition: transform 200ms ease-in-out;
} */

.projectTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.status {
  padding: 5px 15px;
  border-radius: 5px;
}

.projectCenter {
  position: relative;
  margin: 20px 0;
}

.projectName {
  font-size: 20px;
  /* color: var(--bgMainColor); */
  font-weight: 600;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
  /* max-height: 60px; */
  color: #a1a1a1;
  margin: 0 0 10px 0;
}

.description p,
.description span {
  color: #a1a1a1 !important;
}

.avatarMember {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--bgMainColor);
  color: #ffffff;
  display: grid;
  place-items: center;
}
.members {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 5px;
}
.projectBottom {
  position: absolute;
  bottom: 20px;
}

.createdDate {
  font-weight: 600;
}

.date {
  color: #a1a1a1;
}

.process {
  height: 4px;
  border-radius: 2px;
  margin: 15px 0;
  background-color: #009ef7;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.searchWrapper {
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
}

.searchInput {
  width: 300px;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  font-family: var(--mainFontFamily);
  margin-right: 10px;
}

.searchInput:focus {
  outline: none !important;
}

.searchIcon {
  cursor: pointer;
  color: var(--textMainColor);
}

.searchBtn {
  background-color: #ffffff;
  border: none;
}

@media only screen and (max-width: 1199px) {
  .projectListContainer {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .projectListHeader {
    flex-direction: column;
  }
  .searchWrapper {
    width: 100%;
  }
  .searchInput {
    width: calc(100% - 50px);
  }
  .headerRight {
    width: 100%;
    margin-top: 10px;
    gap: 0;
  }
  .form {
    display: flex;
  }
  .headerRight {
    gap: 10px;
  }
  .projectItem {
    height: 330px;
    position: relative;
  }
}

@media only screen and (max-width: 574px) {
  .projectItem {
    height: 330px;
  }
}

@media only screen and (max-width: 500px) {
  .projectItem {
    height: 80vw;
  }
  .projectListContainer {
    margin: 0;
  }
}

@media only screen and (max-width: 400px) {
  .projectItem {
    height: 300px;
  }
  .projectListContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
