.projectThumbnail {
  display: flex;
  align-items: center;
  margin: 10px 10px 20px 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.name {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .projectThumbnail {
    display: none;
  }
}
