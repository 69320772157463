.viewContainer {
  position: fixed;
  z-index: 10 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  cursor: pointer;
}

.optionIcon {
  position: absolute;
  top: 20px;
  right: 80px;
  color: #ffffff;
  cursor: pointer;
}

.image {
  height: 100%;
}

/* .videoContainer {
  margin: auto;
} */

.arrowContainer {
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  cursor: pointer;
}

.prevArrow {
  left: 20px;
}

.nextArrow {
  right: 20px;
}
