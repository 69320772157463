.notiModal {
  border-radius: 10px;
  line-height: initial;
  padding: 10px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  width: 300px;
  max-height: 50vh;
  overflow-y: scroll;
  position: absolute;
  left: -150px;
  top: 120%;
}

.notiModal::-webkit-scrollbar {
  width: 8px;
}

.notiModal::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.notiHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: var(--bgMainColor);
}

.iconItem {
  cursor: pointer;
}

.notiItem {
  display: flex;
  gap: 15px;
  padding: 10px;
}

.notiItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.content {
  text-align: justify;
}

.username {
  font-weight: 600;
}

.notiDateWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unread {
  color: var(--bgMainColor) !important;
}

.createdDate {
  font-size: 14px;
  color: #65676b;
  margin-top: 5px;
}

.unreadIcon {
  width: 10px;
  height: 10px;
  color: var(--bgMainColor);
}

@media only screen and (max-width: 991px) {
  .notiModal {
    right: -8vw;
    left: initial;
  }
}

@media only screen and (max-width: 419px) {
  .notiModal {
    width: 90vw;
    right: -50px;
  }
}
