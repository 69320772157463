.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}

.profileContainer {
  display: flex;
  gap: 5%;
}

.profileLeft {
  width: 65%;
}

.profileRight {
  width: 30%;
}

.profileItem {
  margin-bottom: 10px;
}

.label {
  margin-bottom: 10px;
}

.profileRight .label {
  margin-bottom: 20px;
}

.email {
  word-break: keep-all;
  word-wrap: break-word;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: center;
}

.image {
  width: 200px;
  height: auto;
}

.avatarWrapper {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editAvatarIcon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  right: 6%;
  bottom: 6%;
  cursor: pointer;
}

.submitBtn {
  width: 120px;
  height: 40px;
  background: var(--bgMainColor);
  color: #ffffff;
  margin-top: 30px;
  border: none;
  border-radius: 5px;
}

.submitBtn:active {
  outline: none !important;
  border: none;
}

@media only screen and (max-width: 1199px) {
  .avatarWrapper,
  .avatar {
    width: 150px;
    height: 150px;
  }
  .image {
    width: 150px;
    height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .profileContainer {
    flex-direction: column-reverse;
  }
  .profileLeft,
  .profileRight {
    width: 100%;
  }
  .profileRight {
    margin-bottom: 20px;
  }
  .avatarWrapper {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 574px) {
  .title {
    font-size: 6vw;
  }
}
