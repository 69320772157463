.sidebar {
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  height: calc(100vh - 100px);
  margin: 20px;
  border-radius: 10px;
  padding: 0 0 10px 0;
  position: relative;
  z-index: 2;
}

.sidebar.isResponsive {
  width: 100%;
  margin-left: 20px;
}

.sidebar.isMobile {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: calc(100vh - 60px);
  padding-top: 0;
}

.sidebarHeader {
  margin: 10px;
}

.sidebar.isResponsive .sidebarHeader {
  margin: 10px 20px;
}

.chat {
  font-size: 24px;
  font-weight: 600;
}

.conversations {
  max-height: calc(100vh - 182px);
  overflow-y: auto;
  padding: 0 10px;
}

.sidebar.isMobile .conversations {
  max-height: calc(100vh - 146px);
}

.conversations::-webkit-scrollbar {
  width: 8px;
}

.conversations::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.conversation {
  display: flex;
  align-items: center;
  padding: 10px;
}

.isCurrent {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.conversation:hover {
  cursor: pointer;
}

.conversationWrapper {
  width: 100%;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.conversationTop,
.conversationBottom {
  display: flex;
  justify-content: space-between;
}

.username {
  width: 80%;
  font-weight: 600;
  margin-bottom: 10px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lastMessage {
  font-size: 14px;
  color: #65676b;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lastMessageIcon {
  margin-right: 5px;
}

.lastMessageDate {
  font-size: 12px;
  color: #65676b;
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.addConversation {
  top: -20px;
  right: 0;
  z-index: 4;
  position: absolute;
  display: none;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
}

.iconAddConversation:hover ~ .addConversation {
  display: block;
}

/* .test {
  position: relative;
  display: block;
  z-index: 10;
}

.test::before {
  z-index: 10;
  content: "Thêm";
  position: absolute;
  top: -40px;
  padding: 10px;
  right: 0;
  font-size: 14px;
  background-color: red;
} */

.searchWrapper {
  width: calc(100% - 20px);
}

.searchResult {
  width: 330px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  border-radius: 5px;
  position: absolute;
  padding: 20px 0 10px 10px;
  top: 60px;
}

.newChatModal {
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  border-radius: 5px;
  width: max-content;
  padding: 10px;
  padding-bottom: 5px;
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 1;
}

.newChatModalItem {
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
}

.unreadIconWrapper {
  display: flex;
  align-items: center;
}

.unreadIcon {
  width: 10px;
  height: 10px;
  color: var(--bgMainColor);
}

.iconAddConversation {
  cursor: pointer;
  margin-left: 10px;
}

@media only screen and (max-width: 991px) {
  .searchResult {
    width: 80vw;
  }
}

@media only screen and (max-width: 767px) {
  .lastMessage,
  .newChatModalItem {
    font-size: 3.2vw;
  }
  .lastMessageDate {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 574px) {
  .sidebar {
    filter: none;
  }
  .lastMessage,
  .newChatModalItem {
    font-size: 3.8vw;
  }
  .lastMessageDate {
    font-size: 3.8vw;
  }
  .sidebarHeader {
    margin-top: 16px;
  }
  .iconAddConversation:hover ~ .addConversation {
    display: none;
  }
}

@media only screen and (max-width: 420px) {
  .lastMessage,
  .newChatModalItem {
    font-size: 4.5vw;
  }
  .lastMessageDate {
    font-size: 4vw;
  }
}
