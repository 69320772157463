.cancelButton {
  width: 120px;
  height: 40px;
  background-color: rgba(1, 1, 1, 0.3);
  color: #ffffff;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  box-sizing: border-box;
  font-family: var(--mainFontFamily);
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .cancelButton {
    width: 18vw;
    height: 6vw;
    font-size: 2.5vw;
  }
}

@media only screen and (max-width: 574px) {
  .cancelButton {
    width: 30vw;
    height: 12vw;
    font-size: 5vw;
  }
}

@media only screen and (max-width: 420px) {
  .cancelButton {
    font-size: 4.8vw;
  }
}
