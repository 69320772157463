.popup {
  position: fixed;
  z-index: 999 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

@keyframes popupDisplay {
  0% {
    margin-top: -50px;
  }

  100% {
    margin-top: 10vh;
  }
}

.popupContainer {
  background-color: #ffffff;
  border-radius: 5px;
  min-width: 500px;
  height: fit-content;
  max-height: 75vh;
  margin: 10vh auto auto auto;
  padding: 20px 0;
  position: relative;
  animation-name: popupDisplay;
  animation-duration: 0.5s;
}

.popupChild {
  max-height: calc(75vh - 20px);
  min-height: 200px;
  overflow-y: auto;
}

.popupChild::-webkit-scrollbar {
  width: 8px;
}

.popupChild::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--bgMainColor);
  margin-bottom: 10px;
}

.popupClose {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .popupTitle {
    font-size: 2.6vw;
  }
  .popupContainer {
    width: 80vw !important;
  }
}

@media only screen and (max-width: 574px) {
  .popupContainer {
    min-width: 90vw;
  }
  .popupTitle {
    font-size: 3.5vw;
  }
}

@media only screen and (max-width: 420px) {
  .popupTitle {
    font-size: 5vw;
  }
}
