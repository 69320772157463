.forgetPasswordScreen {
  width: 500px;
  margin: auto;
  margin-top: 15vh;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
}

.logo {
  width: 120px;
  height: auto;
}

.title {
  color: var(--bgMainColor);
  font-weight: 700;
  font-size: 36px;
  margin: 20px 0;
}

.forgetPasswordText {
  margin: 20px 0;
  font-size: 22px;
}

.label {
  margin: 30px 0;
}

.returnLogin {
  background-color: var(--bgMainColor);
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  width: fit-content;
  height: 40px;
  margin: 30px auto;
  border-radius: 5px;
  cursor: pointer;
}

.error {
  margin-top: 10px;
  color: #fd0b0b;
}

.loginLink {
  margin-top: 15px;
}

.loginLink,
.loginLink:hover,
.loginLink:visited {
  color: var(--bgMainColor);
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .forgetPasswordScreen {
    width: 60vw;
    padding: 6vw;
  }
  .logo {
    width: 16vw;
  }
  .title {
    font-size: 4.5vw;
    margin: 2.8vw 0;
  }
  .forgetPasswordText {
    font-size: 3vw;
    margin: 2.8vw 0;
  }
  .label {
    margin: 3.5vw 0;
  }
  .loginLink {
    font-size: 2vw;
    margin-top: 2vw;
  }
  .returnLogin {
    font-size: 2.5vw;
    height: 6vw;
    padding: 0 2.8vw;
    margin: 3.5vw auto;
  }
}

@media only screen and (max-width: 574px) {
  .forgetPasswordScreen {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    padding-top: 15vh;
    padding-bottom: 6vh;
    overflow-y: auto;
  }
  .logo {
    width: 16vw;
  }
  .title {
    font-size: 5vw;
  }
  .forgetPasswordText {
    font-size: 3.2vw;
  }
  .loginLink {
    font-size: 2.8vw;
  }
  .returnLogin {
    font-size: 3.2vw;
    height: 8vw;
    line-height: 8vw;
  }
}

@media only screen and (max-width: 420px) {
  .logo {
    width: 24vw;
  }
  .title {
    font-size: 9vw;
  }
  .label,
  .error {
    font-size: 4.8vw;
    margin: 5vw 0;
  }
  .forgetPasswordText {
    font-size: 6vw;
  }
  .loginLink {
    font-size: 4.8vw;
    margin-top: 5vw;
  }
  .returnLogin {
    font-size: 4.8vw;
    height: 10vw;
    line-height: 10vw;
  }
}
