.taskList {
  width: 100%;
}

.taskItem {
  cursor: pointer;
}

.taskItem:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.nameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.name {
  margin-left: 15px;
}

th.name,
th.description {
  width: 24%;
}

th.project,
td.project {
  width: 15%;
  text-align: center;
}

th.status,
td.status {
  width: 12%;
  text-align: center;
}

th.createdUser,
td.createdUser,
th.deadline,
td.deadline {
  width: 15%;
  text-align: center;
}

th.type,
td.type {
  width: 4%;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .taskList {
    margin: 0;
  }
  .ios {
    width: calc(100% - 40px);
  }
}
