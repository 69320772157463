/* .searchInputContainer {
  display: flex;
  align-items: center;
} */

.searchInput {
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 14px;
}

.searchInput:focus {
  outline: none !important;
}

.label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.required {
  color: #fd0b0b;
  margin-left: 5px;
}
@media only screen and (max-width: 767px) {
  .searchInput {
    font-size: 2.5vw;
  }
}

@media only screen and (max-width: 574px) {
  .searchInput {
    font-size: 2.8vw;
  }
}

@media only screen and (max-width: 420px) {
  .searchInput {
    font-size: 4.2vw;
  }
}
