.title {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}

.passwordError {
  text-align: center;
  margin-top: 10px;
  color: #fd0b0b;
}

@media only screen and (max-width: 574px) {
  .title {
    font-size: 6vw;
  }
}
