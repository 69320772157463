.navbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.navbarItem {
  cursor: pointer;
  padding: 0 20px;
}

.isCurrent {
  font-weight: 600;
}
