.popup {
  position: fixed;
  z-index: 999 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

@keyframes popupDisplay {
  0% {
    margin-top: 0px;
  }

  100% {
    margin-top: 20vh;
  }
}

.popupContainer {
  background-color: #ffffff;
  border-radius: 5px;
  width: 300px;
  height: fit-content;
  margin: 20vh auto auto auto;
  padding: 20px 30px;
  animation-name: popupDisplay;
  animation-duration: 0.5s;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupTitle {
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
