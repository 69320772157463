.alert {
  box-sizing: border-box;
  position: fixed;
  top: 100px;
  right: 50px;
  padding: 10px 20px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.error {
  color: #ffffff;
  background-color: #fd0b0b;
  border-radius: 5px;
}

.success {
  color: #ffffff;
  background-color: #50cd89;
  border-radius: 5px;
}

.closeBtn {
  margin-left: 10px;
  cursor: pointer;
}

.closeBtn:hover {
  color: black;
}
.hide {
  display: none;
}

.message {
  margin-left: 10px;
}
