.iconItem {
  color: var(--bgMainColor);
  margin-left: 20px;
  cursor: pointer;
}

.dragFileElement {
  position: fixed;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 2px dashed rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.required {
  color: #fd0b0b;
  margin-left: 5px;
}
