.memberScreen {
  display: flex;
}

.projectMember {
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
  scrollbar-width: 8px;
  height: calc(100vh - 160px);
  width: calc(100% - 310px);
  margin: 20px;
  margin-left: 0;
}

.projectMember::-webkit-scrollbar {
  width: 8px;
}

.projectMember::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.memberHeader {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}

.memberBody {
  border-radius: 10px;
}

.memberItem:hover {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.addMemberBtn {
  width: 80px;
  height: 30px;
  background-color: var(--bgMainColor);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.username {
  margin-left: 15px;
}

th.name,
td.name {
  width: 32%;
  padding-left: 20px;
}

th.email {
  width: 40%;
}

th.role,
td.role {
  width: 20%;
}

th {
  text-align: left;
}

th.action,
td.action {
  width: 8%;
  text-align: center;
}

.iconItem:hover {
  cursor: pointer;
  color: var(--bgMainColor);
}

.iconItem:last-child {
  margin-left: 15px;
}

@media only screen and (max-width: 1333px) {
  th.name,
  td.name {
    width: 30%;
    padding-left: 20px;
  }

  th.email {
    width: 35%;
  }

  th.role,
  td.role {
    width: 20%;
  }

  th.action,
  td.action {
    width: 15%;
  }
}

@media only screen and (max-width: 1199px) {
  .ios {
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 934px) {
  .action .iconItem:last-child {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .memberScreen {
    flex-direction: column;
  }
  .projectMember {
    margin: 20px;
    padding: 0;
    width: calc(100vw - 30px);
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
}
