.noChat {
  width: calc(100vw - 390px);
  height: calc(100vh - 100px);
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  margin: 20px;
  margin-left: 0;
  border-radius: 10px;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  font-size: 24px;
}

.noChat.isResponsive {
  width: 100%;
  margin-left: 20px;
}

.noChat.isMobile {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: calc(100vh - 50px);
}

.noChatContainer {
  width: 40vw;
  margin: 5vh auto;
}

.noChatHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
}

.noChatBoby {
  text-align: center;
}

.avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-right: 30px;
}

.username {
  font-size: 30px;
  margin-top: 10px;
  color: var(--bgMainColor);
}

.actionGroup {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 30px;
}

.createBtn {
  border: 0;
  border-radius: 25px;
  background-color: var(--bgMainColor);
  color: #ffffff;
  font-size: 18px;
  height: 50px;
  width: fit-content;
  padding: 0 30px;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .noChatContainer {
    width: 70%;
  }
  .createBtn {
    font-size: 2.2vw;
  }
}

@media only screen and (max-width: 767px) {
  .noChat {
    font-size: 3.5vw;
  }
  .username {
    font-size: 4.5vw;
  }
  .avatar {
    width: 120px;
    height: 120px;
  }
  .createBtn {
    padding: 0 2.8vw;
  }
}

@media only screen and (max-width: 574px) {
  .noChat {
    font-size: 3.8vw;
  }
  .username {
    font-size: 4.8vw;
  }
  .noChatContainer {
    width: 100%;
  }
  .avatar {
    width: 100px;
    height: 100px;
  }
  .actionGroup {
    flex-direction: column;
    align-items: center;
    gap: 2.5vw;
  }
  .createBtn {
    width: 32vw;
    height: 10vw;
    font-size: 3.2vw;
    padding: 0 3.2vw;
  }
}
