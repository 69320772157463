.form {
  padding: 0 20px 0 20px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
}

.label {
  margin-bottom: 10px;
}

.projectInfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
