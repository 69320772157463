.editHistoryContainer {
  padding: 0 20px 0 20px;
}

.noHistory {
  margin-top: 10px;
  text-align: center;
}

.editHistoryItem {
  display: flex;
  margin-top: 15px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.editHistoryRight {
  width: 100%;
}

.editHistoryTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.username {
  font-weight: 600;
}

.changedDate {
  color: #65676b !important;
}

.arrowIcon {
  margin: 0 10px;
}
