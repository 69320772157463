.featureScreen {
  display: flex;
}

.feature {
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: 8px;
  height: calc(100vh - 60px);
  width: 100%;
  padding: 20px;
}

.feature::-webkit-scrollbar {
  width: 8px;
}

.feature::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.featureHeader {
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: white;
}

.featureName {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
  margin-bottom: 10px;
}

.description p,
.description span {
  color: var(--textMainColor) !important;
}

.actionGroup {
  position: relative;
  display: flex;
  gap: 15px;
}
.edit,
.delete,
.add {
  top: -28px;
  right: 0;
  z-index: 4;
  position: absolute;
  display: none;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: none;
}

.add {
  right: 40px;
}

.iconEdit:hover ~ .edit {
  display: block;
}

.iconDelete:hover ~ .delete {
  display: block;
}

.iconAdd:hover ~ .add {
  display: block;
}

.iconItem {
  cursor: pointer;
}

.iconItem:hover {
  color: var(--bgMainColor);
}

.label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.subfeatureContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: white;
  padding: 15px 20px;
}

.subfeatureItem {
  width: 150px;
  height: 50px;
  border: 1px solid rgba(1, 1, 1, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 15px;
}

.createBtn {
  width: 80px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid var(--bgMainColor);
  border-radius: 5px;
  color: var(--bgMainColor);
  cursor: pointer;
  margin-top: 20px;
}

.modalWrapper {
  position: relative;
}

.modal {
  border-radius: 10px;
  line-height: 30px;
  padding: 10px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  width: max-content;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 10 !important;
}

.modalItem {
  cursor: pointer;
}

.modalItem:hover {
  color: var(--bgMainColor);
}

.baseInfo {
  padding: 20px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: white;
}

@media only screen and (max-width: 767px) {
  .featureScreen {
    flex-direction: column;
  }
  .featureScreen {
    margin: 0;
    padding: 0;
    width: 100vw;
  }
}
