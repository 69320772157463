.projectScreen {
  display: flex;
}

.projectDetail {
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: 8px;
  height: calc(100vh - 100px);
  width: calc(100% - 290px);
  margin: 20px 0;
  padding-right: 20px;
  margin-left: 0;
}

.projectDetail::-webkit-scrollbar {
  width: 8px;
}

.projectDetail::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.detailHeader {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
}

.detailHeaderRight {
  position: relative;
}

.edit,
.delete {
  top: -28px;
  right: 0;
  z-index: 4;
  position: absolute;
  display: none;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: none;
}

.iconEdit:hover ~ .edit {
  display: block;
}

.iconDelete:hover ~ .delete {
  display: block;
}

.createFeatureBtn {
  width: 100px;
  height: 30px;
  background-color: var(--bgMainColor);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.createFeature {
  width: 80px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid var(--bgMainColor);
  border-radius: 5px;
  color: var(--bgMainColor);
  cursor: pointer;
  margin: 10px 0 10px 10px;
}

.iconItem {
  margin-left: 15px;
  cursor: pointer;
}

.iconItem:hover {
  color: var(--bgMainColor);
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}

.label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.projectName {
  font-size: 18px;
  color: var(--bgMainColor);
}

.content {
  text-align: justify;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.projectProcessWrapper {
  margin: 10px 0;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.featureTable tr:last-child {
  border-bottom: 2px dashed #ebedf2;
}

.username {
  margin-left: 15px;
}

.featureItem {
  cursor: pointer;
}

.featureItem:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

th.name,
th.description {
  width: 40%;
}

th.createdUser,
th.createdDate,
td.createdUser,
td.createdDate {
  width: 20%;
  text-align: center;
}

td.createdUser .nameWrapper {
  justify-content: center;
}

th.action,
td.action {
  width: 10%;
  text-align: right;
}

td.action {
  position: relative;
}

.editProject,
.deleteProject {
  top: -20px;
  right: 0;
  z-index: 4;
  position: absolute;
  display: none;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: none;
}

.iconEditProject:hover ~ .editProject {
  display: block;
}

.iconDeleteProject:hover ~ .deleteProject {
  display: block;
}

th.dropdown,
td.dropdown,
th.type,
td.type {
  width: 4%;
  text-align: center;
}

td.name,
td.description {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th.name,
td.name {
  padding-left: 20px;
}

.iconItem {
  cursor: pointer;
}

.iconItem:last-child {
  margin-left: 15px;
}

.baseInfoWrapper {
  background-color: white;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
}

.createChildWrapper {
  position: relative;
}

.createChildModal {
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  border-radius: 5px;
  width: max-content;
  padding: 10px;
  padding-bottom: 5px;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
  text-align: left;
}

.modalItem {
  margin-bottom: 5px;
}

.unfeature {
  padding: 20px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: white;
}

.description p,
.description span {
  color: var(--textMainColor) !important;
}

@media only screen and (max-width: 1199px) {
  .ios {
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 1183px) {
  .actionGroup {
    display: flex;
    flex-direction: column;
  }
  .iconDeleteProject {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .projectScreen {
    flex-direction: column;
    height: calc(100vh - 60px);
  }
  .projectDetail {
    margin: 20px;
    margin-right: 0;
    padding: 0;
    padding-right: 20px;
    width: calc(100vw - 20px);
  }
}
