.container {
  text-align: center;
  padding: 0 20px;
}

.text {
  margin: 10px 0;
}

.checkboxGroup {
  overflow-y: auto;
  margin-top: 10px;
  margin-right: -20px;
}

.addMemberCheckbox {
  margin-right: -20px;
}

.checkboxGroup::-webkit-scrollbar,
.addMemberCheckbox::-webkit-scrollbar {
  width: 8px;
}

.checkboxGroup::-webkit-scrollbar-thumb,
.addMemberCheckbox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.addMemberCheckbox {
  height: 42vh;
  overflow-y: auto;
  margin-top: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 419px) {
  .avatar {
    width: 40px;
    height: 40px;
  }
}

.newChatContainer {
  padding: 0 20px;
}

.newChatContainer .searchConversationWrapper {
  margin-top: 10px;
  margin-right: -20px;
}
