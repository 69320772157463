.header {
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  height: 60px;
  padding: 0 30px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.headerLeft .headerItem:hover {
  color: var(--bgMainColor);
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}

.logo {
  color: var(--bgMainColor);
  font-weight: 700;
  font-size: 22px;
}

.logo:hover {
  cursor: pointer;
}

.headerItem {
  display: flex;
  align-items: center;
  position: relative;
}

.headerItem:hover {
  cursor: pointer;
}

.headerItem:hover .iconItem,
.currentItem {
  color: var(--bgMainColor);
}

.iconItem {
  background-color: rgba(0, 0, 0, 0.05);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.username {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.angleIcon {
  width: 10px;
  height: 10px;
}

.modal {
  border-radius: 10px;
  line-height: 30px;
  padding: 10px 20px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  width: max-content;
}

.project {
  position: relative;
}

.projectModal {
  position: absolute;
  left: 0;
  top: 100%;
}

.profileModal {
  position: absolute;
  right: 0;
  top: 100%;
}

.navbar {
  position: relative;
}

.navbarModal {
  position: absolute;
  left: 0;
  top: 70%;
}

.modalItem {
  cursor: pointer;
}

.modalItem:hover {
  color: var(--bgMainColor);
}

.profileItemText {
  margin-left: 10px;
}

.projectsWrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.unreadAmount {
  position: absolute;
  top: 10%;
  right: 10%;
  height: 14px;
  font-size: 12px;
  color: #ffffff;
  background-color: #f65a5b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.oneDigit {
  width: 14px;
  border-radius: 50%;
}

.twoDigit {
  padding: 0 2px;
  border-radius: 40%;
}

.createTaskBtn {
  width: 90px;
  height: 30px;
  background-color: var(--bgMainColor);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 0 2.8vw;
  }
}

@media only screen and (max-width: 574px) {
  .header {
    filter: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .logo {
    font-size: 3.4vw;
  }
  .headerRight {
    gap: 10px;
  }
  .createTaskBtn {
    width: 20vw;
    height: 8vw;
    line-height: 8vw;
    font-size: 4vw;
  }
  .modal {
    line-height: 8vw;
  }
}
