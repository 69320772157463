.inputWrapper {
  width: 100%;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  font-family: var(--mainFontFamily);
}

.dateInput {
  -webkit-min-logical-width: calc(100% - 20px);
}

.textarea {
  height: auto;
  padding: 10px 20px;
  font-family: var(--mainFontFamily);
}

.textarea::-webkit-scrollbar {
  width: 8px;
}

.textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.input:focus {
  outline: none !important;
  border: 1px solid #3c3950;
}

.input.wrong-input {
  outline: none !important;
  border: 1px solid #fd0b0b;
}

.rightInput {
  font-size: 12px;
  text-align: left;
  margin-block-start: 5px;
  margin-block-end: 0;
}

.wrongInput {
  font-size: 12px;
  color: #fd0b0b;
  text-align: left;
  margin-block-start: 5px;
  margin-block-end: 0;
}

.label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.required {
  color: #fd0b0b;
  margin-left: 5px;
}

.disabled {
  opacity: 0.5;
}

.submitBtn {
  width: 120px;
  height: 40px;
  background: var(--bgMainColor);
  color: #ffffff;
  margin-top: 20px;
  cursor: pointer;
}

.submitBtn:active {
  outline: none !important;
  border: none;
}

.inputPasswordWrapper {
  position: relative;
}

.iconItem {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.inputField {
  display: flex;
}

.process {
  position: relative;
  top: 12px;
  right: 25px;
  width: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 767px) {
  .input {
    font-size: 2.5vw;
    padding: 0 2.8vw;
    height: 7vw;
  }
  .submitBtn {
    width: 18vw;
    height: 6vw;
  }
  .label {
    margin-top: 3vw;
  }
  .iconItem {
    top: 2.6vw;
    right: 2.6vw;
  }
  .wrongInput,
  .rightInput {
    font-size: 2vw;
  }
  .textarea {
    height: 20vw !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 574px) {
  .input {
    font-size: 5vw;
    height: 10vw;
  }
  .submitBtn {
    width: fit-content;
    min-width: 30vw;
    height: 12vw;
  }
  .label {
    margin-top: 2.9vw;
  }
  .wrongInput,
  .rightInput {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 420px) {
  .input {
    font-size: 4.8vw;
    height: 12vw;
  }
  .label {
    font-size: 4.8vw;
    margin-top: 3.2vw;
  }
  .wrongInput,
  .rightInput {
    font-size: 4vw;
  }
  .iconItem {
    top: 4.5vw;
    right: 4vw;
  }
}
