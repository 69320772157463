.nameWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.choosenUser .nameWrapper {
  margin: 3px 0;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.name {
  margin-left: 15px;
  font-size: 14px;
}

.searchResult {
  position: absolute;
  padding: 20px 20px 10px 20px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  width: calc(100% - 80px);
  max-height: 240px;
  overflow-y: auto;
  z-index: 2;
}

.searchResult::-webkit-scrollbar {
  width: 8px;
}

.searchResult::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.choosenUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.required {
  color: #fd0b0b;
  margin-left: 5px;
}

.wrongInput {
  font-size: 12px;
  color: #fd0b0b;
  text-align: left;
  margin-block-start: 5px;
  margin-block-end: 0;
}

@media only screen and (max-width: 420px) {
  .name {
    font-size: 4.5vw;
  }
  .searchResult {
    box-sizing: border-box;
    padding: 10px;
    width: calc(100% - 40px);
    min-width: initial;
  }
  .nameWrapper,
  .name {
    width: 100%;
    overflow: hidden;
  }
  .fullname,
  .email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
