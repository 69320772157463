.taskListScreen {
  padding: 30px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.taskListScreen::-webkit-scrollbar {
  width: 8px;
}

.taskListScreen::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
  /* margin-bottom: 20px;
  padding: 20px;
    background-color: white;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1)); */
}

.taskListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.searchWrapper {
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
}

.searchInput {
  width: 300px;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  font-family: var(--mainFontFamily);
  margin-right: 10px;
}

.searchInput:focus {
  outline: none !important;
}

.searchIcon {
  cursor: pointer;
  color: var(--textMainColor);
}

.searchBtn {
  background-color: #ffffff;
  border: none;
}

@media only screen and (max-width: 1199px) {
  .taskListScreen {
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .taskListScreen {
    max-height: calc(100vh - 100px);
    padding: 20px;
  }
  .taskListHeader {
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 0px;
  }
  .searchWrapper {
    width: 100%;
  }
  .searchInput {
    width: calc(100% - 50px);
  }
  .title {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 574px) {
  .taskListScreen {
    max-height: calc(100vh - 80px);
    padding: 20px;
  }
}
