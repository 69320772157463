.documentScreen {
  display: flex;
}

.projectDocument {
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: 8px;
  height: calc(100vh - 60px);
  width: calc(100% - 290px);
  padding: 20px;
  padding-left: 0;
}

.projectDocument::-webkit-scrollbar {
  width: 8px;
}

.projectDocument::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}

.documentHeader {
  background-color: white;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addDocumentBtn {
  width: 80px;
  height: 30px;
  background-color: var(--bgMainColor);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .documentScreen {
    flex-direction: column;
    height: calc(100vh - 60px);
  }

  .projectDocument {
    margin: 20px;
    margin-right: 0;
    padding: 0;
    padding-right: 12px;
    width: calc(100vw - 20px);
    height: calc(100vh - 160px);
  }
}
