.ql-toolbar {
  border-radius: 5px 5px 0 0;
}

.ql-container {
  border-radius: 0 0 5px 5px;
  height: 120px;
  /* position: absolute; */
}

.ql-editor::-webkit-scrollbar {
  width: 8px;
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.wrapper {
  height: 160px;
}

@media only screen and (max-width: 466px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
  }
}

@media only screen and (max-width: 400px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
  }
  .wrapper {
    height: 180px;
  }
}
