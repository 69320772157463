:root {
  --textMainColor: #3c3950;
  --bgMainColor: #00b59c;
  --mainFontFamily: "Source Sans Pro";
  --bgColor: #f2f3f5;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--mainFontFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--textMainColor);
  background-color: var(--bgColor);
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  border-spacing: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  border-collapse: collapse;
}

th {
  padding: 15px 20px;
}

td {
  padding: 10px 20px;
}

tr {
  border-bottom: 2px dashed #ebedf2;
}

tbody tr:last-child {
  border-bottom: none;
}

p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

@media only screen and (max-width: 934px) {
  table {
    display: inline-block;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 3.2vw;
  }
}

@media only screen and (max-width: 574px) {
  body {
    font-size: 4.2vw;
  }
}

@media only screen and (max-width: 420px) {
  body {
    font-size: 4.8vw;
  }
}

/*Fix Focus Safari*/
@supports (-webkit-overflow-scrolling: touch) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
    font-size: 16px !important;
  }
}
/*end focus*/

/*/ adjust increase/decrease button /*/
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

/*/ adjust clear button /*/
input[type="date"]::-webkit-clear-button {
  z-index: 1;
}
