.resetPasswordScreen {
  width: 500px;
  margin: auto;
  margin-top: 15vh;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
}

.logo {
  width: 120px;
  height: auto;
}

.title {
  color: var(--bgMainColor);
  font-weight: 700;
  font-size: 36px;
  margin: 20px 0;
}

.resetPasswordText {
  margin: 20px 0;
  font-size: 22px;
}

.loginLink {
  margin-top: 15px;
}

.loginLink,
.loginLink:hover,
.loginLink:visited {
  color: var(--bgMainColor);
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .resetPasswordScreen {
    width: 60vw;
    padding: 6vw;
  }
  .logo {
    width: 16vw;
  }
  .title {
    font-size: 4.5vw;
    margin: 2.8vw 0;
  }
  .resetPasswordText {
    font-size: 3vw;
    margin: 2.8vw 0;
  }
  .label {
    margin: 3.5vw 0;
  }
  .loginLink {
    font-size: 2vw;
    margin-top: 2vw;
  }
}

@media only screen and (max-width: 574px) {
  .resetPasswordScreen {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    padding-top: 15vh;
    padding-bottom: 6vh;
    overflow-y: auto;
  }
  .logo {
    width: 16vw;
  }
  .title {
    font-size: 5vw;
  }
  .resetPasswordText {
    font-size: 3.2vw;
  }
  .loginLink {
    font-size: 2.8vw;
    margin-top: 2.5vw;
  }
}

@media only screen and (max-width: 420px) {
  .logo {
    width: 24vw;
  }
  .title {
    font-size: 9vw;
  }
  .resetPasswordText {
    font-size: 6vw;
  }
  .loginLink {
    font-size: 4.8vw;
    margin-top: 4.8vw;
  }
}
