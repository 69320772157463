.input {
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  font-family: var(--mainFontFamily);
}

.label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.required {
  color: #fd0b0b;
  margin-left: 5px;
}

.addBtn {
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  width: fit-content;
  background-color: var(--bgMainColor);
  color: #ffffff;
  cursor: pointer;
}
