.sidebar {
  box-sizing: border-box;
  background-color: white;
  margin: 20px;
  padding: 10px;
  width: 250px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.08));
  height: calc(100vh - 100px);
}

.sidebarItem {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}

.sidebarItem:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
}

.isCurrent {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .sidebar {
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 40px);
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 574px) {
  .sidebar {
    padding: 5px;
  }
  .sidebarItem {
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 385px) {
  .sidebarItem {
    font-size: 4.2vw;
  }
}
