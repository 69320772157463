.documentContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
}

.documentItem {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 160px;
  overflow: hidden;
  border-radius: 5px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
}

.projectDocumentContainer {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
}

.image {
  width: 200px;
  height: auto;
}

.playVideoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.3);
}

.fileItem {
  width: 120px;
  height: 120px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.documentDetail {
  background-color: #65676b;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
}

.name {
  padding: 0 5px;
  line-height: 30px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionGroup {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  gap: 5px;
  z-index: 2;
}

.documentItem:hover .actionGroup {
  display: flex;
}

.actionItem {
  background-color: #65676b;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.actionItem:hover {
  color: var(--bgMainColor);
}

.downloadDocument {
  color: var(--textMainColor);
}

.fileNameWrapper {
  box-sizing: border-box;
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px 5px 0;
  background-color: #ffffff;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: var(--textMainColor);
}

.downloadBtn {
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: var(--bgMainColor);
}

@media only screen and (max-width: 476px) {
  .documentContainer {
    justify-content: center;
  }
}
