.fileViewContainer {
  position: fixed;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  cursor: pointer;
}

.imageContainer {
  text-align: center;
  margin-top: 200px;
}

.text {
  color: #ffffff;
  font-size: 22px;
  margin: 0 20px 20px 20px;
}

.button {
  width: 100px;
  height: 30px;
  background-color: var(--bgMainColor);
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}
