.task {
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 8px;
  height: calc(100vh - 100px);
  width: 100%;
  padding: 20px;
}

.task::-webkit-scrollbar {
  width: 8px;
}

.task::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.taskHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: white;
}

.edit,
.delete,
.document,
.link,
.history {
  top: -28px;
  right: 0;
  z-index: 4;
  position: absolute;
  display: none;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: none;
}

.iconEdit:hover ~ .edit {
  display: block;
}

.iconDelete:hover ~ .delete {
  display: block;
}

.iconLink:hover ~ .link {
  display: block;
}

.iconDocument:hover ~ .document {
  display: block;
}

.iconHistory:hover ~ .history {
  display: block;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: var(--bgMainColor);
}

.description p,
.description span {
  color: var(--textMainColor) !important;
}

.actionGroup {
  text-align: right;
  position: relative;
}

.actionGroup .iconItem {
  margin-left: 15px;
  cursor: pointer;
}

.taskBody {
  display: flex;
  gap: 20px;
}

.taskBodyLeft,
.taskBodyRight {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: white;
}

.taskBodyLeft {
  padding-top: 0;
}

.taskBodyLeft {
  width: 70%;
}

.taskBodyRight {
  width: 30%;
}

.taskBodyLeft .label {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.status {
  border-radius: 5px;
  padding: 5px 10px;
  width: 100px;
  text-align: center;
  color: #ffffff;
}

.process {
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
}

.wrapper {
  border: 1px solid rgba(1, 1, 1, 0.1);
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.wrapperItem {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.wrapperItem .label {
  width: 40%;
}

.wrapperItem .content {
  display: flex;
  width: 60%;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.username {
  margin-left: 15px;
}

.assignee {
  display: flex;
  align-items: center;
}

.addAssignee {
  font-size: 14px;
  color: var(--bgMainColor);
  cursor: pointer;
}

.description .content {
  word-wrap: break-word;
}

.createBtn {
  width: 90px;
  height: 30px;
  background-color: var(--bgMainColor);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.editContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconItem:hover {
  cursor: pointer;
  color: var(--bgMainColor);
}

@media only screen and (max-width: 1199px) {
  .task {
    height: calc(100vh - 60px);
  }
  .taskHeader {
    width: calc(100vw - 80px);
  }
  .taskBody {
    flex-direction: column-reverse;
  }
  .taskBodyLeft {
    width: calc(100vw - 80px);
    margin-bottom: 0;
  }
  .taskBodyRight {
    width: calc(100vw - 80px);
    margin-bottom: 0;
  }
  .taskScreen {
    flex-direction: column;
  }
}
