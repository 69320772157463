.searchResultContainer {
  max-height: 50vh;
  overflow-y: scroll;
}

.searchResultContainer::-webkit-scrollbar {
  width: 8px;
}

.searchResultContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.searchResultItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.searchResultItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.noResult {
  padding-bottom: 10px;
  text-align: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.isCurrent {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.noResult {
  text-align: center;
  margin-top: 10px;
}

@media only screen and (max-width: 419px) {
  .avatar {
    height: 40px;
    width: 40px;
  }
}
