.popup {
  position: fixed;
  z-index: 999 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

@keyframes popupDisplay {
  0% {
    margin-top: 0px;
  }

  100% {
    margin-top: 20vh;
  }
}

.popupContainer {
  background-color: #ffffff;
  border-radius: 5px;
  width: 360px;
  height: fit-content;
  margin: 20vh auto auto auto;
  padding: 20px 0;
  animation-name: popupDisplay;
  animation-duration: 0.5s;
}

.popupContent {
  padding: 0 30px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: var(--bgMainColor);
}

.message {
  text-align: justify;
}

.ok {
  color: var(--bgMainColor);
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  cursor: pointer;
}
